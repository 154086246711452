import React, {Fragment, useState} from 'react';
import PortfolioGridThreeColumn from "../../../templates/portfolio/grid/three-column";
import portfolioData from '../../../data/portfolio'
import SideHeader from "../../../components/SideHeader";
import HeaderTwo from "../../../components/header/HeaderTwo";
import FooterFour from "../../../components/footer/FooterFour";

const PortfolioGridThreeColumnPage = () => {
    const [count, setCount] = useState(portfolioData.length);
    const [loading, setLoading] = useState(false);
    const portfolios = portfolioData.slice(0, count);

    const loadMore = () => {
        setLoading(true);
        setTimeout(() => {
            setCount(count + 3);
            setLoading(false);
        }, 200);
    };
	
	const alertThis = () => {
		alert("this is a test");
	};

    const allItems = count >= portfolioData.length;

    return (
        
            <div className={'main-wrapper has-side-header'}>
				<HeaderTwo/>
				<SideHeader mobile={false}/>
				<PortfolioGridThreeColumn portfolios={portfolios} loading={loading} allItems={allItems} loadHandler={loadMore}/>
			</div>
       
    );
};

export default PortfolioGridThreeColumnPage;