import React, {Fragment, useState} from 'react';
import Header from "../../../components/header/HeaderOne";
import SideHeader from "../../../components/SideHeader";
import HeaderTwo from "../../../components/header/HeaderTwo";
import TemplatePortfolioDetails from "../../../templates/portfolio/details";
import portfolioData from '../../../data/portfolio'

const PortfolioDetailsPage = () => {
	
	const [count, setCount] = useState(portfolioData.length);
	const portfolios = portfolioData.slice(0, count);
	
	const queryParams = new URLSearchParams(window.location.search);
	let queryId = queryParams.get('id');
	
	const currentPortfolio = portfolioData[queryId - 1];
	
    return (
	<fragment>
	<div className={'main-wrapper has-side-header'}>
				<HeaderTwo/>
				<SideHeader mobile={false}/>
				<div class="section-wrap section section-padding bg-grey" >
				<div class="container"><div class="row">
  <div class="col-lg-5">
  
<div class="portfolio-details-content">
    <a href="/" class="category">HTML, CSS, JS, PHP, MYSQL</a>
    <h1 class="title">Loaders Garage Invoicing</h1>
  </div></div>
</div>
<div class="row portfolio-details-content">
  <div class="col">
  <h2>Brief</h2>
    <p>A web-application custom built using Bootsrap 4 framework, PHP backend and MYSQL integration.</p></div>
</div>
  
<div class="row portfolio-details-content">
  <div class="col-lg-6">
    <img src={require('../../../assets/images/portfolio/portfolio-3/login.png')}/>
  </div>
  <div class="col-lg-6">
	<img src={require('../../../assets/images/portfolio/portfolio-3/invoicing.png')}/>
</div>
</div>

<div class="row">
  <div class="col">
  <div class="portfolio-details-content">
 <h2>What is it?</h2>
<p>The customer inputs details of a customer, their vehicle, work carried out by the garage as a table, labour time and noted for the customer. This information is then stored to an SQL Database and subsequently sent to an FPDF implimentation to create a formatted PDF in a printable format with two copies to be filed and given to the customer.</p>
  <p>This project required a login, invoicing page, admin tools for sales insights and configurable options for VAT Rate, Labour Rate and to add new users. These all increase productivity, replacing the original paper based system that required manual addition and totalling of parts and generating RRPs for items by automating these steps. Storing the information also allows for quick searching of previous invoices which was previously not possible with physical filing. Another benefit is the ability to check earlings on a month by month basis shown visibly on a scatter graph and pie chart showing estimated revenue and a break down of earnings.</p>
</div>
</div></div>
<div class="row portfolio-details-content">
  <div class="col-lg-4 col-md-6 col-12">
	<img src={require('../../../assets/images/portfolio/portfolio-3/insights.png')}/>
  </div>
  <div class="col-lg-4 col-md-6 col-12">
	<img src={require('../../../assets/images/portfolio/portfolio-3/invoice.png')}/>
  </div>
  <div class="col-lg-4 col-md-6 col-12">
	<img src={require('../../../assets/images/portfolio/portfolio-3/settings.png')}/>
  </div>
</div>

<div class="row portfolio-details-content">
  <div class="col">
  <div class="project-share"><span>Share:</span><a href="/"><i class="fa fa-facebook"></i></a><a href="/"><i class="fa fa-twitter"></i></a><a href="/"><i class="fa fa-pinterest-p"></i></a><a href="/"><i class="fa fa-google-plus"></i></a></div>
</div>
</div>
</div>
</div>
					
			</div>
			</fragment>
    );
};

export default PortfolioDetailsPage;